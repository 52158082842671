import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import {
  Grid,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Modal,
  Divider,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Searchbar } from 'pages/commonComponents/Navbar/Searchbar';

const HeaderImg = () => {
  const img = process.env.PUBLIC_URL + '/auth-bg.png';
  return (
    <>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          backgroundImage: `url(${img})`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '360px',
          position: 'relative',
        }}
      >
       {' '}
        {/* <Grid item lg={12} md={12} sm={12} xs={12} style={{height:'361px'}}>
                    <img src={img} alt='Truck' style={{height: '100%', width: '100%'}} />
                </Grid> */}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="flex"
          justifyContent="center"
          alignItems="center"
        >
          <HeaderContent />
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderImg;

const HeaderContent = () => {
  const theme = useTheme();
  const isLarge = theme.breakpoints.up('lg');
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Grid container lg={6} md={12} sm={12} xs={12} rowGap="10px">
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{ color: '#FFC200', fontSize: '16px', fontWeight: '600' }}
          >
            Resources
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{ color: '#FFFFFF', fontSize: '48px', fontWeight: '600' }}
          >
            SMR blog
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{ color: '#FFF9E5', fontSize: '20px', fontWeight: '400' }}
          >
            Tool and strategies modern teams need to help thier compnies grow.
          </Typography>
        </Grid>
        <Grid
          item
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="center"
          alignItems="center"
          className=" flex"
          gap={1}
          paddingTop="15px"
        >
          <Grid
            item
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <Grid item lg={6} md={4} sm={5} xs={12}>
              <Searchbar placeholder="Enter your email" />
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                style={{
                  width: '100%',
                  height: '48px',
                  backgroundColor: '#FFC200',
                  color: '#03131D',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                Subscribe
              </Button>
            </Grid>
          </Grid>
          <Grid item lg={8} md={6} sm={7} xs={12}>
            <Typography
              style={{ color: '#FFF9E5', fontSize: '14px', fontWeight: '400' }}
            >
              We care about your data in our privacy policy
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
