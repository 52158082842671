import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import PermIdentityIcon from '@mui/icons-material/PermIdentity';
// import LandingPage from 'pages/LandingPage';
import LandingPage from 'pages/LandingPage/Index';
import CommonBg from 'pages/Auth/CommonBg';
import { Forgotpassword } from 'pages/Auth/ForgotPassword/Forgotpassword';
import Accounts from '../pages/Accounts/Accounts';
import Profile from '../pages/Accounts/Profile/Profile';
import Privacy from 'pages/Privacy/Privacy';
import EquipmentTab from '../pages/SMRRInfo/EquipmentTab';
import ImagSliderComponent from '../pages/SMRRInfo/EquipmentInfo/EquipmentDetailsPage';
import InventoryListing from 'pages/InventoryListing/index';
import MainComponent from '../pages/SMRRInfo/EquipmentInfo/EquipmentDetailsPage';
// import { InventoryListing} from 'pages/InventoryListing/index';
import { ProductSell } from 'pages/ProductSell/index';
import { Favourite } from 'pages/Favourite';
import { CalculateEMI } from 'pages/productDetailFlow/CalculateEMI';
import { ProductDetailMain } from 'pages/productDetailFlow';
import { ApplyForFinance } from 'pages/productDetailFlow/ApplyForFinance';
import { RequestQuoteSuccessPage } from 'pages/productDetailFlow/RequestQuoteSuccessPage';
import { PaymentMethod } from 'pages/SMRRInfo/PaymentMethod';
import { Billing } from 'pages/SMRRInfo/PaymentMethod/Billing';
import { ResponseStatusPage } from 'pages/SMRRInfo/PaymentMethod/ResponseStatusPage';
import TransactionSec from 'pages/SMRRInfo/Transactions/Transactoins';
import { Financing } from 'pages/Financing';
import HelpCenter from '../pages/SMRRInfo/HelpCenter/HelpCenter';
import Settings from 'pages/SMRRInfo/Settings/Settings';
import PushNotifications from 'pages/SMRRInfo/Settings/PuchNotifications';
import { NonSmrFinancing } from 'pages/NonSMRFinancing';
import IndustryNews from 'pages/IndustryNews/IndustryNews';
import Blogs from 'pages/Blogs/Blogs';
import AboutUs from 'pages/AboutUs/AboutUs';
import ContactUs from 'pages/ContactUs/ContactUs';
import Disclaimer from 'pages/Disclaimer/Disclaimer';
import ChangePassword from '../pages/SMRRInfo/Settings/ChangePassword';
import ConfimationPage from '../pages/SMRRInfo/Settings/ConfirmationPage';
import FinancingListing from 'pages/FinancingListing';
import FinancingDetail from 'pages/FinancingDetail';
import { FinancingPayment } from 'pages/FinancingPayment';
import BlogPost from 'pages/Blogs/BlogPost';

const RouteConstants = {
  REDIRECTOR: '/',
  LOGIN: '/login',
};

export const NavigationLinks = [
  {
    name: 'Profile',
    icon: (
      <img
        className="text-white max-w-[18px] max-h-[20px] px-2"
        src="/assets/icons/account_profile.svg"
      />
    ),
    selectedIcon: <InsertDriveFileIcon className="text-primary-500 mr-2" />,
    redirectLink: '/accounts/profile',
  },
  {
    name: 'Equipment Info',
    icon: (
      <img
        className="max-w-[18px] max-h-[20px] px-2"
        src="/assets/icons/account_equipment_info.svg"
      />
    ),
    selectedIcon: <PermIdentityIcon className="text-primary-500 mr-2" />,
    redirectLink: '/accounts/Equipment-Info',
  },

  {
    name: 'Transactions',
    icon: (
      <img
        className="max-w-[18px] max-h-[20px] px-2"
        src="/assets/icons/account_transactions.svg"
      />
    ),
    selectedIcon: <PermIdentityIcon className="text-primary-500 mr-2" />,
    redirectLink: '/accounts/transactions',
  },
  {
    name: 'Payment Methods',
    icon: (
      <img
        className="max-w-[18px] max-h-[20px] px-2"
        src="/assets/icons/account_payment.svg"
      />
    ),
    selectedIcon: <PermIdentityIcon className="text-primary-500 mr-2" />,
    redirectLink: '/accounts/payments',
  },
  {
    name: 'Help Center',
    icon: (
      <img
        className="max-w-[18px] max-h-[20px] px-2"
        src="/assets/icons/account_help_center.svg"
      />
    ),
    selectedIcon: <PermIdentityIcon className="text-primary-500 mr-2" />,
    redirectLink: '/accounts/help-center',
  },
  {
    name: 'Settings',
    icon: (
      <img
        className="max-w-[18px] max-h-[20px] px-2"
        src="/assets/icons/account_settings.svg"
      />
    ),
    selectedIcon: <PermIdentityIcon className="text-primary-500 mr-2" />,
    redirectLink: '/accounts/settings',
  },
];

export const routes = {
  REDIRECTOR: {
    pathname: '/',
    title: 'Smr - Sell My Rigs',
    isPublic: true,
    isNavbar: true,
    isFooter: true,
    isSubNavbar: true,
    element: <LandingPage />,
  },
  SIGNUP: {
    pathname: '/signup',
    title: 'Smr - Signup',
    isPublic: true,
    isNavbar: false,
    isFooter: false,
    isSubNavbar: false,
    element: <CommonBg />,
  },
  LOGIN: {
    pathname: '/login',
    title: 'Login',
    isPublic: true,
    isNavbar: false,
    isFooter: false,
    isSubNavbar: false,
    element: <CommonBg />,
  },
  CREATE_ACCOUNT: {
    pathname: '/createaccount',
    title: 'Smr - Signup',
    isPublic: true,
    isNavbar: false,
    isFooter: false,

    element: <CommonBg />,
  },
  PAYMENT_BILLLING: {
    pathname: '/payment-billing',
    title: 'Smr - Payments',
    isPublic: false,
    isNavbar: true,
    isFooter: false,
    isSubNavbar: false,

    element: <Billing />,
  },
  FINANCE_PAYMENT_BILLLING: {
    pathname: '/finance-payment-billing',
    title: 'Smr-Finance-Payments',
    isPublic: false,
    isNavbar: true,
    isFooter: false,
    isSubNavbar: false,
    element: <FinancingPayment />,
  },
  FORGOT_PASSWORD: {
    pathname: '/forgot-password',
    title: 'Forgot Passowrd',
    isPublic: true,
    isNavbar: false,
    isFooter: false,
    element: <Forgotpassword />,
  },
  INVENTORY_LISTING: {
    pathname: '/inventory',
    title: 'Inventory Listing',
    isPublic: true,
    isNavbar: true,
    isFooter: true,
    isSubNavbar: true,
    element: <InventoryListing />,
  },
  PAYMENT_METHODS: {
    pathname: '/accounts/payments',
    title: 'Payments',
    isPublic: false,
    isNavbar: true,
    isFooter: false,
    isSubNavbar: false,
    element: <PaymentMethod />,
  },
  // TRANSACTIONS:{
  //   pathname: '/accounts/transactions',
  //   title: 'Payments',
  //   isPublic: false,
  //   isNavbar:true,
  //   isFooter:false,
  //   isSubNavbar:false,
  //   element: <> <h1>Transactions</h1>
  //    </>

  // },

  LEADS: {
    pathname: '/leads',
    title: 'Leads',
    isPublic: false,
    isNavbar: false,
    isFooter: false,
    element: <>Leads</>,
  },
  PRODUCT_DETAILS: {
    pathname: `/inventory-details`,
    title: 'Leads',
    isPublic: true,
    isNavbar: true,
    isFooter: false,
    isSubNavbar: false,
    element: <ProductDetailMain />,
  },
  RESPONSE_STATUS: {
    pathname: `/response-status`,
    title: 'Leads',
    isPublic: true,
    isNavbar: false,
    isFooter: false,
    isSubNavbar: false,
    element: <ResponseStatusPage />,
  },
  SUCCESS: {
    pathname: `/success`,
    title: 'Leads',
    isPublic: true,
    isNavbar: false,
    isFooter: false,
    isSubNavbar: false,
    element: <RequestQuoteSuccessPage />,
  },
  REQUEST_FINANCING: {
    pathname: '/request-financing',
    title: 'Request Financing',
    isPublic: false,
    isNavbar: true,
    isFooter: false,
    isSubNavbar: false,
    element: <ApplyForFinance />,
  },
  FINANCING: {
    pathname: '/financing',
    title: 'Request Financing',
    isPublic: false,
    isNavbar: true,
    isFooter: false,
    isSubNavbar: false,
    element: <Financing />,
  },
  FINANCING_LISTING: {
    pathname: '/financing-listing',
    title: 'Financing Listing',
    isPublic: false,
    isNavbar: true,
    isFooter: false,
    isSubNavbar: false,
    element: <FinancingListing />,
  },
  OTHER_EQUIPMENT_FINANCING: {
    pathname: '/other-equiment-financing',
    title: 'Request Financing for other Equipments',
    isPublic: false,
    isNavbar: true,
    isFooter: false,
    isSubNavbar: false,
    element: <NonSmrFinancing />,
  },
  FAVOURITE_PAGE: {
    pathname: '/my-wishlist',
    title: 'Leads',
    isPublic: false,
    isNavbar: true,
    isFooter: true,
    isSubNavbar: true,
    element: <Favourite />,
  },
  PROFILE: {
    pathname: '/accounts/profile',
    title: 'Profile',
    isPublic: false,
    isNavbar: true,
    isFooter: false,
    isSubNavbar: false,
    element: <Profile />,
  },
  Equipment_Info: {
    pathname: '/accounts/Equipment-Info',
    title: 'EquipmentInfo',
    isNavbar: true,
    isFooter: false,
    isPublic: false,
    isSubNavbar: false,
    element: <EquipmentTab />,
  },
  FINANCING_LISTING_DETAIL: {
    pathname: '/financing-detail',
    title: 'Financing Listing Detail',
    isPublic: false,
    isNavbar: true,
    isFooter: false,
    isSubNavbar: false,
    element: <FinancingDetail />,
  },
  PeoductDeatils: {
    pathname: '/accounts/Equipment-Info/:id',
    title: 'EquipmentInfo',
    isNavbar: true,
    isFooter: false,
    isPublic: false,
    isSubNavbar: false,
    element: <MainComponent />,
  },
  Transactions: {
    pathname: `/accounts/transactions`,
    title: 'Transaction',
    isPublic: false,
    isNavbar: true,
    isFooter: false,
    isSubNavbar: false,
    element: <TransactionSec />,
  },
  Help_Center: {
    pathname: '/accounts/help-center',
    title: 'Help Center',
    isPublic: false,
    isNavbar: true,
    isSubNavbar: false,
    isFooter: false,
    element: <HelpCenter />,
  },
  Settings: {
    pathname: '/accounts/settings',
    title: 'Settings',
    isPublic: false,
    isNavbar: true,
    isSubNavbar: false,
    isFooter: false,
    element: <Settings />,
  },
  Notifications: {
    pathname: '/accounts/notifications',
    title: 'Notifications',
    isPublic: false,
    isNavbar: true,
    isSubNavbar: false,
    isFooter: false,
    element: <PushNotifications />,
  },
  ChangePassword: {
    pathname: '/accounts/change-password',
    title: 'Notifications',
    isPublic: false,
    isNavbar: true,
    isSubNavbar: false,
    isFooter: false,
    element: <ChangePassword />,
  },
  ConfimationPage: {
    pathname: '/accounts/confirmation-page',
    title: 'Notifications',
    isPublic: false,
    isNavbar: true,
    isSubNavbar: false,
    isFooter: false,
    element: <ConfimationPage />,
  },
  Industry_News: {
    pathname: '/industry-news',
    title: 'Industry-News',
    isPublic: true,
    isNavbar: true,
    isSubNavbar: true,
    isFooter: true,
    element: <IndustryNews />,
  },
  Blogs: {
    pathname: '/blogs',
    title: 'Blogs',
    isPublic: true,
    isNavbar: true,
    isSubNavbar: true,
    isFooter: true,
    element: <Blogs />,
  },
  About_us: {
    pathname: '/about-us',
    title: 'about-us',
    isPublic: true,
    isNavbar: true,
    isSubNavbar: true,
    isFooter: true,
    element: <AboutUs />,
  },
  Contact_us: {
    pathname: '/contact-us',
    title: 'contact-us',
    isPublic: true,
    isNavbar: true,
    isSubNavbar: true,
    isFooter: true,
    element: <ContactUs />,
  },
  PRODUCT_SELL: {
    pathname: '/product-sell',
    title: 'Product Sell Journey',
    isPublic: true,
    isNavbar: true,
    isSubNavbar: false,
    isFooter: false,
    element: <ProductSell />,
  },
  Disclaimer: {
    pathname: '/disclaimer',
    title: 'Disclaimer',
    isPublic: true,
    isNavbar: true,
    isSubNavbar: true,
    isFooter: true,
    element: <Disclaimer />,
  },
  PRIVACY_POLICY: {
    pathname: '/Privacy',
    title: 'Privacy Policy',
    isPublic: true,
    isNavbar: true,
    isFooter: true,
    isSubNavbar: true,
    element: <Privacy />,
  },
  BLOG_POST: {
    pathname: '/blogs/:id',
    title: 'Blog Post',
    isPublic: true,
    isNavbar: true,
    isFooter: true,
    isSubNavbar: true,
    element: <BlogPost />,
  },
};

export const routeKey = Object.assign(
  {},
  ...Object.keys(routes).map((routeKey) => ({
    [routes[routeKey].pathname]: routeKey,
  }))
);

export const pageDetails = routes[routeKey[window.location.pathname]];

export default RouteConstants;
