import { useLocation } from 'react-router';
import { useMemo } from 'react';
import { routes, routeKey } from '../routes/RouteConstants';

const usePageDetails = () => {
  const location = useLocation();

  // Function to check if path matches route pattern
  const matchRoute = (routePath, currentPath) => {
    const pattern = routePath
      .replace(/:[^/]+/g, '[^/]+')
      .replace(/\//g, '\\/');
    
    const regex = new RegExp(`^${pattern}$`);
    return regex.test(currentPath);
  };

  const pageConfig = useMemo(() => {
    // First try exact match using existing logic
    const exactMatch = routes[routeKey[location.pathname] || 'DEFAULT'];
    if (exactMatch) return exactMatch;

    // If no exact match, try pattern matching
    const matchingRoute = Object.values(routes).find(route => 
      matchRoute(route.pathname, location.pathname)
    );

    return matchingRoute || routes['DEFAULT'];
  }, [location.pathname]);

  // Keep existing console logs
  console.log('Location in usePageDetails:', location.pathname);
  console.log('Route key:', routeKey[location.pathname]);
  console.log('Routes:', routes);

  return pageConfig;
};

export default usePageDetails;