import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import Content from './ContentSec';
import HeaderImg from './HeaderImg';
import { blogDb } from '../../firebase/blogFirebase';
import { collection, getDocs } from 'firebase/firestore';

const Blogs = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const querySnapshot = await getDocs(collection(blogDb, 'blog-posts'));
                const fetchedPosts = [];
                querySnapshot.forEach((doc) => {
                    fetchedPosts.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                setPosts(fetchedPosts);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching posts:', err);
                setError(err.message);
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <>
            <HeaderImg />
            <Container maxWidth="lg">
                {posts.map((post) => (
                    <Content 
                        key={post.id}
                        {...post}
                    />
                ))}
            </Container>
        </>
    );
};

export default Blogs;
