import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const blogFirebaseConfig = {
  apiKey: process.env.REACT_APP_BLOG_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_BLOG_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_BLOG_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BLOG_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_BLOG_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_BLOG_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_BLOG_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase with a custom name for blogs
const blogApp = initializeApp(blogFirebaseConfig, 'blog-app');
const analytics = getAnalytics(blogApp);
export const blogDb = getFirestore(blogApp);
export const blogStorage = getStorage(blogApp); 