import { lazy, Suspense, useMemo } from 'react';
import { Route, Routes, Outlet, Navigate, useLocation } from 'react-router-dom';
import { getLocalStorageItem, localStorageKeys } from 'hooks';
import RouteConstants, { routeKey } from 'routes/RouteConstants';

import { Layout, TopNav, SeoHelmet } from 'components';
import { routes } from 'routes/RouteConstants';
import LayoutNavbarFooter from 'components/Layout/LayoutNavbarFooter';
import usePageDetails from 'hooks/usePathName';

// import AppContainer from 'Pages/AppContainer';
const NotFound404 = lazy(() => import('pages/404'));
const DefaultScreen = lazy(() => import('pages/DefaultScreen'));
const PostJob = lazy(() => 'pages/PostJob');
const RoutesPage = () => {
   
  const AllRoutes = useMemo(
    () => (
      <>
        {/* <Suspense fallback={null}> */}
        {Object.values(routes)
          // .filter(
          //   (routeDetails) => routeDetails.isPublic && routeDetails.element
          // )
          .map((routeDetails) => (
            <Route
              key={`public-route-${routeDetails?.pathname}`}
              path={routeDetails?.pathname}
              element={
                <Suspense fallback={null}>{routeDetails?.element}</Suspense>
              }
            />
          ))}
        {/* </Suspense> */}
      </>
    ),
    []
  );
  const PrivateRoutes = useMemo(
    () => (
      <>
        {/* <Suspense fallback={null}> */}
        {Object.values(routes)
          .filter(
            (routeDetails) => !routeDetails.isPublic && routeDetails.element
          )
          .map((routeDetails) => (
            <Route
              key={`private-route-${routeDetails.pathname}`}
              path={routeDetails.pathname}
              element={
                <Suspense fallback={null}>{routeDetails.element}</Suspense>
              }
            />
          ))}
        {/* </Suspense> */}
      </>
    ),
    []
  );
  return (
    <>
      <div className="">
        <SeoHelmet />
        {/* <Layout  > */}

        <Routes>
          {/* {PublicRoutes} */}

          <Route element={<ProtectedRoutes   />}>
            {/* Dashboard */}

            {AllRoutes}
            {/* <Route path = {RouteConstants.P} */}
            <Route path="*" element={<DefaultScreen />} />
            {/* <Route path="*" element={<NotFound404 />} /> */}

            {/* Not Found */}
          </Route>
          <Route path="*" element={<DefaultScreen />} />

          {/* <Route path="*" element={<NotFound404 />} /> */}
        </Routes>
        {/* </Layout> */}
      </div>
    </>
  );
};
const ProtectedRoutes = () => {
  const pageDetails = usePageDetails();
  const location = useLocation();
  const isLoggedIn = getLocalStorageItem(localStorageKeys.AUTH_TOKEN);
  const isPublic = pageDetails?.isPublic;

  // Debugging logs
  console.log('Current location:', location.pathname);
  console.log('Page details:', pageDetails);
  console.log('Is public:', isPublic);
  console.log('Is logged in:', isLoggedIn);

  return isPublic || isLoggedIn ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={RouteConstants?.LOGIN} replace state={{ from: location }} />
  );
};

export default RoutesPage;
