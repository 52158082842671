import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Typography, Grid, Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { blogDb } from '../../firebase/blogFirebase';
import { collection, getDocs } from 'firebase/firestore';

const BlogPost = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);
    const [error, setError] = useState(null);
    const [relatedPosts, setRelatedPosts] = useState([]);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const querySnapshot = await getDocs(collection(blogDb, 'blog-posts'));
                const posts = [];
                querySnapshot.forEach((doc) => {
                    posts.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                const currentPost = posts.find(post => post.id === id);
                
                if (currentPost) {
                    setPost(currentPost);
                    
                    // Get related posts (same category)
                    const related = posts
                        .filter(p => p.category === currentPost.category && p.id !== currentPost.id)
                        .slice(0, 3);
                    setRelatedPosts(related);
                } else {
                    setError('Post not found');
                }
            } catch (err) {
                console.error('Error fetching post:', err);
                setError(err.message);
            }
        };

        fetchPost();
    }, [id]);

    const formatContentWithLinks = (content) => {
        if (!content) return '';
        
        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        
        // Split content by URLs and map through parts
        const parts = content.split(urlRegex);
        
        return parts.map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a 
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            color: '#0F5E91',
                            textDecoration: 'underline',
                            '&:hover': {
                                textDecoration: 'none'
                            }
                        }}
                    >
                        {part}
                    </a>
                );
            }
            return part;
        });
    };

    if (error) return <div>Error: {error}</div>;
    if (!post) return <div>Loading...</div>;

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {/* Breadcrumbs */}
            <Breadcrumbs 
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ mb: 3 }}
            >
                <Link 
                    to="/"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                >
                    Home
                </Link>
                <Link 
                    to="/blogs"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                >
                    Blogs
                </Link>
                <Typography color="text.primary">{post.title}</Typography>
            </Breadcrumbs>

            <Grid container spacing={3}>
                {/* Image */}
                <Grid item xs={12}>
                    {post.imageUrl && (
                        <img 
                            src={post.imageUrl}
                            alt={post.title}
                            style={{
                                width: '100%',
                                maxHeight: '500px',
                                objectFit: 'cover',
                                borderRadius: '8px'
                            }}
                        />
                    )}
                </Grid>

                {/* Content */}
                <Grid item xs={12}>
                    <Typography 
                        variant="h3" 
                        component="h1" 
                        gutterBottom
                        sx={{
                            fontSize: { xs: '2rem', md: '2.5rem' },
                            fontWeight: 600,
                            color: '#1a1a1a',
                            mb: 2
                        }}
                    >
                        {post.title}
                    </Typography>

                    {post.subtitle && (
                        <Typography 
                            variant="h5" 
                            gutterBottom
                            sx={{
                                fontSize: { xs: '1.2rem', md: '1.4rem' },
                                color: '#666',
                                mb: 3
                            }}
                        >
                            {post.subtitle}
                        </Typography>
                    )}

                    <Typography 
                        variant="subtitle1" 
                        sx={{
                            color: '#666',
                            mb: 4,
                            pb: 2,
                            borderBottom: '1px solid #eee'
                        }}
                    >
                        By {post.author} | {new Date(post.date).toLocaleDateString()} | {post.category}
                    </Typography>

                    <Typography 
                        variant="body1" 
                        sx={{
                            fontSize: '1.1rem',
                            lineHeight: 1.8,
                            color: '#333',
                            whiteSpace: 'pre-wrap',
                            '& a': {  // Style for all links
                                color: '#0F5E91',
                                textDecoration: 'underline',
                                '&:hover': {
                                    textDecoration: 'none'
                                }
                            }
                        }}
                    >
                        {formatContentWithLinks(post.content)}
                    </Typography>
                </Grid>

                {/* Related Posts */}
                {relatedPosts.length > 0 && (
                    <Grid item xs={12} sx={{ mt: 6, pt: 4, borderTop: '1px solid #eee' }}>
                        <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                            Related Posts
                        </Typography>
                        <Grid container spacing={3}>
                            {relatedPosts.map((relatedPost) => (
                                <Grid item xs={12} md={4} key={relatedPost.id}>
                                    <Link 
                                        to={`/blogs/${relatedPost.id}`}
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <Typography variant="h6" gutterBottom>
                                            {relatedPost.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {relatedPost.category} | {new Date(relatedPost.date).toLocaleDateString()}
                                        </Typography>
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default BlogPost; 