import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Content = ({
  id,
  title,
  subtitle,
  content,
  author,
  date,
  category,
  imageUrl,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blogs/${id}`);
  };

  const overlayStyle = {
    backgroundColor: 'rgba(15, 94, 145, 0.75)',
    backdropFilter: 'blur(4px)',
    width: '100%',
    height: '92px',
    position: 'absolute',
    bottom: '0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
  };

  const formattedDate = date ? new Date(date).toLocaleDateString() : '';

  return (
    <Box
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: 3,
        },
        borderRadius: 2,
        overflow: 'hidden',
        mb: 4,
        bgcolor: '#F5F5F5',
        marginTop: '2rem',
      }}
    >
      <Grid container spacing={0}>
        {/* Image Section */}
        <Grid item xs={12} style={{ position: 'relative', height: '345px' }}>
          <img
            src={imageUrl}
            alt={title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          <div style={overlayStyle}>
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: '#FFFFFF',
                  mb: 0.5,
                }}
              >
                {author}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#FFFFFF',
                }}
              >
                {formattedDate}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                marginRight: '2rem',
                color: '#FFFFFF',
                bgcolor: 'rgba(255, 255, 255, 0.2)',
                px: 2,
                py: 0.5,
                borderRadius: 1,
              }}
            >
              {category}
            </Typography>
          </div>
        </Grid>

        {/* Content Section */}
        <Grid item xs={12} sx={{ p: 3 }}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: '600',
              color: '#03131D',
              mb: 1,
            }}
          >
            {title}
          </Typography>

          {subtitle && (
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: '500',
                color: '#666666',
                mb: 2,
              }}
            >
              {subtitle}
            </Typography>
          )}

          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '500',
              color: '#0F5E91',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Read More →
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Content;
